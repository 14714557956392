import Avatar from '@components/avatar'
import { Fragment } from 'react'
import { Check, X } from "react-feather";
import { toast } from 'react-toastify'
import moment from "moment";
import { useTranslation } from "react-i18next";

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#42b0ee', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})


export const getAllStatus = [
  { value: 0, label: "Saved", label_en: "Saved", label_jp: "保存" },
  { value: 1, label: "Logged In", label_en: "Logged In", label_jp: "ログイン済み" },
  { value: 2, label: "Suspend", label_en: "Suspend", label_jp: "一時停止" },
  { value: 3, label: "Invited", label_en: "Invited", label_jp: "招待中" },
  { value: 4, label: "Suspend", label_en: "Suspend", label_jp: "一時停止" }];


export const getEmployeeLabelByStatus = (status, lang = 'en') => {
  const currentObj = getAllStatus.find(resp => resp.value === status);
  if (currentObj) {
    return currentObj['label_' + lang];
  }
}
export const deletedStatus = {
  0: 'Relieve',
}
export const getDeletedStatus = [
  { value: 1, label: "Saved1", label_en: "Relieve", label_jp:"退職済み" },
  ];

  export const getDeletedByStatus = (status, lang = 'en') => {
    const currentObj = getDeletedStatus.find(resp => resp.value === status);
    if (currentObj) {
      return currentObj['label_' + lang];
    }
  }

export const getTimeInMinutes = (max = 1440, interval = 30, start = 0) => {
  let time = [];
  for (let i = start; i <= max; i++) {
    if (i % interval == 0) {
      time.push(i);
    }
  }
  return time;
}
export const getNotificationTimeInMinutes = (max = 60, interval = 30, start = 30) => {
  let time = [5, 10];
  for (let i = start; i <= max; i++) {
    if (i % interval == 0) {
      time.push(i);
    }
  }
  return time;
}
export const getTimeFormat = (time) => {
  let formatedTime = [];
  let value = '';
  formatedTime = time.split(":");
  value = formatedTime[0] + ":" + formatedTime[1]
  return (value.toString());
};
export const getWorkHourTiming = (time) => {
  let formatedTime = [];
  let value = '';
  formatedTime = time.split(":");
  value = formatedTime[2]
  return (value.toString());
};
//Code by Priya(working_hours format change) on 7/22/22
export const getWorkHourTimingForFlex = (time) => {
  let formatedTime = [];
  let value = '';
  formatedTime = time.split(":");
  value = formatedTime[0]
  return (value.toString());
};
export const getTimeList = (max = 1410, interval = 30, start = 0) => {
  let time = [];
  var hours, minutes;
  for (var i = 0; i <= 1410; i += 30) {
    hours = Math.floor(i / 60);
    minutes = i % 60;
    if (minutes < 10) {
      minutes = "0" + minutes; // adding leading zero
    }
    if (hours < 10) {
      hours = "0" + hours; // adding leading zero
    }
    time.push(hours + ":" + minutes);
  }
  return time;
};

export const getTimeListSec = (max = 1410, interval = 30, start = 0) => {
  let time = [];
  var hours, minutes;
  for (var i = 0; i <= 1410; i += 30) {
    hours = Math.floor(i / 60);
    minutes = i % 60;
    if (minutes < 10) {
      minutes = "0" + minutes; // adding leading zero
    }
    if (hours < 10) {
      hours = "0" + hours; // adding leading zero
    }
    time.push(hours + ":" + minutes + ":00");
  }
  return time;
};

export const getTimeList15 = (max = 1425, interval = 15, start = 0) => {
  let time = [];
  var hours, minutes;
  for (var i = 0; i <= 1425; i += 15) {
    hours = Math.floor(i / 60);
    minutes = i % 60;
    if (minutes < 10) {
      minutes = "0" + minutes; // adding leading zero
    }
    if (hours < 10) {
      hours = "0" + hours; // adding leading zero
    }
    time.push(hours + ":" + minutes);
  }
  return time;
};

export const getTimeList15Sec = (max = 1425, interval = 15, start = 0) => {
  let time = [];
  var hours, minutes;
  for (var i = 0; i <= 1425; i += 15) {
    hours = Math.floor(i / 60);
    minutes = i % 60;
    if (minutes < 10) {
      minutes = "0" + minutes; // adding leading zero
    }
    if (hours < 10) {
      hours = "0" + hours; // adding leading zero
    }
    time.push(hours + ":" + minutes + ":00");
  }
  return time;
};

export const SuccessToast = ({ msg }) => {
  const { t } = useTranslation();
  return (<Fragment>
    <div className='toastify-header'>
      <div className='title-wrapper'>
        <Avatar size='sm' color='success' icon={<Check size={12} />} />
        {/* <h6 className='toast-title'>{t('success')}</h6> */}
        <div className='toastify-body'>
      <span role='img' aria-label='toast-text'>
        {msg}
      </span>
    </div>
      </div>
    </div>
    {/* <div className='toastify-body'>
      <span role='img' aria-label='toast-text'>
        {msg}
      </span>
    </div> */}
  </Fragment>)
}

export const notifySuccess = (msg) => toast.success(<SuccessToast msg={msg} />, { icon: false, hideProgressBar: true })


// export const holidays = ["M", "T", "W", "T", "F", "S", "S"];
export const holidays = [
  {
    id:1,
    label: "月",
    value: "M"
  },
  {
    id:2,
    label: "火",
    value: "T"
  },
  {
    id:3,
    label: "水",
    value: "W"
  },
  {
    id:4,
    label: "木",
    value: "T"
  },
  {
    id:5,
    label: "金",
    value: "F"
  },
  {
    id:6,
    label: "土",
    value: "S"
  },
  {
    id:7,
    label: "日",
    value: "S"
  },
]


export const convertMinutesToHours = (time) => {
  const hours = time / 60;
  return hours;
}

export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const statusObj = {
  pending: 'light-warning',
  active: 'light-success',
  inactive: 'light-secondary'
}

export const USER_TYPES = {
  COMPANY_ADMIN: "company_admin",
  OPERATOR_ADMIN: "operator_admin",
  INITIAL_ADMIN: "initial_admin",
  EMPLOYEE: "employee"
}

export const getAdminUsers = (data) => {
  return data ? data.filter(value => value.user_type === USER_TYPES.OPERATOR_ADMIN || value.user_type === USER_TYPES.INITIAL_ADMIN) : [];
}

export const getPaginationParams = (currentPage, rowsPerPage) => {
  const from = (currentPage - 1) * rowsPerPage;
  const to = rowsPerPage;
  return { from, to };
}

export const actionStatus = {
  suspend: "Suspend",
  add: "Add",
  delete: "Delete"
}

export const approvalStatus = [{ id: 1, label: "承認済み" }, { id: 0, label: "承認待ち" }];
export const attendance_approval_status = [{ id: 1, label: "at_approved" }, { id: 0, label: "at_un_approved" }];

export const approvalStatusObj = {
  0: 'saved-badge',
  1: 'approve-badge ',
  2: 'suspended-badge',
  3: 'pending-badge',
  4: 'suspended-badge',
}

export const convertMinToHours = (totalMinutes) => {
  var hours = Math.floor(totalMinutes / 60).toString().padStart(2, 0);
  var minutes = (totalMinutes % 60).toString().padStart(2, 0);
  return hours + ":" + minutes;
}

export const convertMinToHoursEdit = (totalMinutes) => {
  var hours = Math.floor(totalMinutes / 60).toString().padStart(2, 0);
  var minutes = (totalMinutes % 60).toString().padStart(2, 0);
  return hours + ":" + minutes + ":" + "00";
}

export const convertHoursToMinutes = (totalHours) => {
  console.log(totalHours)
  if (totalHours) {
    const [hours, minutes, seconds] = totalHours.split(':');
    console.log(Number(hours) * 60 + Number(minutes))
    return Number(hours) * 60 + Number(minutes);
  }
}
export const convertNotificationHoursToMinutes = (totalHours) => {
  if (totalHours) {
    const [hours, minutes, seconds] = totalHours.split(':');
    console.log(Number(minutes) * 60 + Number(seconds))
    return Number(minutes) * 60 + Number(seconds);
  }
}

export const convertNotificationTime= (totalHours) => {
  if (totalHours) {
    const [hours, minutes, seconds] = totalHours.split(':');
    return (Number(hours)*60)+Number(minutes);
  }
}



const ErrorToast = ({ msg }) => {
  const { t } = useTranslation();
  return (<Fragment>
    <div className='toastify-header'>
      <div className='title-wrapper'>
        <Avatar size='sm' color='danger' icon={<X size={12} />} />
        {/* <h6 className='toast-title'>{t('error_toast')}</h6> */}
        <div className='toastify-body'>
      <span role='img' aria-label='toast-text'>
        {msg}
      </span>
    </div>
      </div>
    </div>
    {/* <div className='toastify-body'>
      <span role='img' aria-label='toast-text'>
        {msg}
      </span>
    </div> */}
  </Fragment>)
}

export const notifyError = (msg) => toast.error(<ErrorToast msg={msg} />, { icon: false, hideProgressBar: true })

export const attendanceStatus = [
  {
    id: 0,
    label_en: "Approve",
    label_jp: "承認待ち"
  },
  {
    id: 1,
    label_en: "Approved",
    label_jp: "承認済み"
  }
]

export const getAttendanceStatus = (status, lan = 'en') => {
  const cObj = attendanceStatus.find(value => value.id === status);
  if (cObj) {
    return cObj['label_' + lan];
  }
}

export const contentSettings = [
  {
    node: 'co_contact',
    label: "Internal Contact"
  },
  {
    node: 'att_mgmt',
    label: "Attendance management"
  },
  {
    node: 'salary_info',
    label: "Salary Details"
  },
  {
    node: 'expense',
    label: "Expense Management"
  },
  {
    node: 'facility',
    label: "Fecility"
  },
  {
    node: 'cybosu',
    label: 'Cybosu',
  }
];


export const getTimeObjFromTime = (time) => {
  if (time) {
    const [hours, mins, sec] = time.split(":");
    return {
      hours,
      mins,
      sec
    }
  } else {
    return {
      hours: "",
      mins: "",
      sec: ""
    }
  }
}

export const getDayInMonth = (day) => {
  let dates = [];
  var start = moment(new Date()).day(day).format("YYYY-MM-DD")
  const end = moment().endOf('year').format("YYYY-MM-DD")
  const startWeek = moment(start, "YYYY-MM-DD").week()
  const endWeek = moment(end, "YYYY-MM-DD").isoWeek()
  const nextYear = moment(end, "YYYY-MM-DD").add(1,"y").weeksInYear()
  if((moment(start, "YYYY-MM-DD").format("MM")) < 10){
  for (var i = startWeek ; i<= endWeek ; i++){
    if(moment().add(1,'days').format("YYYY-MM-DD") <= moment().day(day).week(i).format("YYYY-MM-DD")){
    dates.push(moment().day(day).week(i).format("YYYY-MM-DD"))
    }
  }} else {
    for (var i = startWeek ; i<= (endWeek + nextYear) ; i++){
      if(moment().add(1,'days').format("YYYY-MM-DD") <= moment().day(day).week(i).format("YYYY-MM-DD")){
      dates.push(moment().day(day).week(i).format("YYYY-MM-DD"))
      }
    }
  }
  // var day = moment().startOf('month').day(day);
  // if (day.date() > 7) day.add(7, 'd');
  // var month = day.month();
  // while (month === day.month()) {
  // // for( var month = start; month <=  ; month++ ){
  //   dates.push(moment(day).format("YYYY-MM-DD"));
  //   day.add(7, 'd');
  // }
  return dates;
}

export const getDayInMonth2 = (day) => {
  let dates = [];
  var start = moment(new Date()).day(day).format("YYYY-MM-DD")
  const end = moment().endOf('year').format("YYYY-MM-DD")
  const startWeek = moment(start, "YYYY-MM-DD").week()
  const endWeek = moment(end, "YYYY-MM-DD").isoWeek()
  const nextYear = moment(end, "YYYY-MM-DD").add(1,"y").weeksInYear()
  if((moment(start, "YYYY-MM-DD").format("MM")) < 10){
  for (var i = startWeek ; i<= (endWeek == 1 ? 53 : endWeek) ; i++){
    if(moment().add(1,'days').format("YYYY-MM-DD") <= moment().day(day).week(i).format("YYYY-MM-DD")){
    dates.push(moment().day(day).week(i).format("YYYY-MM-DD"))
    }
  }}else {
    for (var i = startWeek ; i<= (endWeek + nextYear + 1) ; i++){
      if(moment().add(1,'days').format("YYYY-MM-DD") <= moment().day(day).week(i).format("YYYY-MM-DD")){
      dates.push(moment().day(day).week(i).format("YYYY-MM-DD"))
      }
    }
  }
  // var day = moment().startOf('month').day(day);
  // if (day.date() > 7) day.add(7, 'd');
  // var month = day.month();
  // while (month === day.month()) {
  // // for( var month = start; month <=  ; month++ ){
  //   dates.push(moment(day).format("YYYY-MM-DD"));
  //   day.add(7, 'd');
  // }
  return dates;
}

export const getDayByValue = (value) => {
  let day;
  if (value == 1) {
    day = "Monday";
  } else if (value == 2) {
    day = "Tuesday";
  }
  else if (value == 3) {
    day = "Wednesday";
  }
  else if (value == 4) {
    day = "Thursday";
  }
  else if (value == 5) {
    day = "Friday";
  }
  else if (value == 6) {
    day = "Saturday";
  }
  else if (value == 7) {
    day = "Sunday";
  }
  return day;
}

export const convertUtcToLocal = (time) => {
  return moment.utc(time).local().format("HH:mm")
}

export const convertToTimeFormat = (time) => {
  if (time) {
    const [hours, minutes, seconds] = time.split(':');
    return {
      hours: hours,
      minutes: minutes
    }
  } else {
    return "-"
  }
}

export const phoneCountryList = ["in", "jp"];

export const attendanceDateFormat = (date) => {
  if (date && date != "0000-00-00 00:00:00") {
    return moment(date).format("YYYY/MM/DD hh:mm");
  }
  return "-";
}

export const getDatesByMonth = (month) => {
  if (month) {
    const year = moment().format("YYYY");
    const dates = moment(year + "-" + month).daysInMonth();
    return dates;
  }
  return 31;
}

export const getAllWeekInMonth = (day = 'Sunday') => {
  var selectedDay = moment().startOf('month').day("Monday");
  var list = [];
  if (selectedDay.date() > 7) selectedDay.add(7, 'd');
  var month = selectedDay.month();
  while (month === selectedDay.month()) {
    list.push(selectedDay.add(7, 'd'));
  }
  return list.length;
}